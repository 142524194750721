$(document).ready(function () {

    $(document).on('click', '.js-custom-select button', function (e) {
        e.preventDefault()

        $(this).parent().find('ul').toggle()
    })

    $(document).on('click', '.js-custom-select li', function (e) {
        e.preventDefault()

        const self = $(this)
        const customSelect = self.closest('.js-custom-select')
        const customSelectButton = customSelect.find('button')
        const customSelectOptions = customSelect.find('ul')

        const value = self.data('id')
        const text = self.text()

        customSelect.find('input').val(value).trigger('change')
        customSelectButton.text(text)

        customSelectOptions.hide()
    })

    $(document).on('click', function (e) {
        const customSelect = $(e.target).closest('.js-custom-select')
        if (!customSelect.length) {
            $('.js-custom-select ul').hide()
        }
    })
})