let referrer

function auth(obj) {
    referrer = $(obj).prop('href')

    loadModalDialog('/auth/form/', '#authorization')

    return false
}

function showAuthErrors(parent, errors) {

    let firstItem

    $.each(errors, function (i, obj) {
        $('input[name*=\'' + i + '\']', parent).caption(obj[0], {align: 'top', color: 'red'})
        if (firstItem === undefined) {
            firstItem = $('input[name*=\'' + i + '\']', parent)
        }
    })

    firstItem && firstItem.focus()

    return false
}


$(document).ready(function () {
    $(document).on('submit', '.js-auth-form', function (e) {
        e.preventDefault()

        const self = $(this)
        if (!self.valid()) {
            return false
        }

        $.post(self.prop('action'), self.serialize(), function (result) {
            if (result && result.errors) {
                return showAuthErrors(self, result.errors)
            }
            if (referrer) {
                return window.location.href = referrer
            }
            window.location.reload()
        }, 'json')
    })

    $(document).on('submit', '.js-recovery-form', function (e) {
        e.preventDefault()
        const self = $(this)
        if (!$(this).valid()) {
            return false
        }

        $.post(self.prop('action'), self.serialize(), function (result) {
            if (result.errors) {
                return showAuthErrors(self, result.errors)
            }
            if (result.success) {
                openModalDialog('#recoverySuccessful')
            }
        }, 'json')
    })
})