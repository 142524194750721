$(document).ready(function () {
    $('.js-top-burger').on('click', function () {
        const self = $(this)
        const spans = self.find('span')

        self.toggleClass('absolute fixed top-[-64px] sm:top-0 top-0')
        spans.eq(0).toggleClass('hidden')
        spans.eq(1).toggleClass('top-[25px] top-1/2 -translate-y-1/2 rotate-45 bg-white bg-black')
        spans.eq(2).toggleClass('top-[35px] top-1/2 -translate-y-1/2 -rotate-45 bg-white bg-black')

        $('.js-top-menu').toggleClass('-translate-x-full -translate-x-0')
    })

    let shouldShowSubMenu = false
    const topSubMenu = $('.js-top-submenu')
    const hideMenuOnLeave = () => {
        shouldShowSubMenu = false
        setTimeout(() => {
            !shouldShowSubMenu && topSubMenu.hide()
        }, 1000)
    }
    topSubMenu
        .on('mouseenter', () => shouldShowSubMenu = true)
        .on('mouseleave', hideMenuOnLeave)

    $('.js-toggle-submenu')
        .on('mouseenter', (obj) => {
            if (window.innerWidth < 1200) {
                return
            }
            const subMenu = $(obj.target).data('menu')

            topSubMenu.fadeOut('fast')
            $(`.js-submenu-${subMenu}`).fadeIn('fast')

            shouldShowSubMenu = true
        })
        .on('mouseleave', hideMenuOnLeave)
})