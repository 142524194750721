
function message(m, t) {
    $.jGrowl(m, {theme: t, life: 5000})
}

function error(m) {
    return message(m, 'error')
}

function success(m) {
    return message(m, 'success')
}

function openModalDialog(selector) {
    $('.js-dialog-container').addClass('hidden')

    $(selector).removeClass('hidden')
}

function loadModalDialog(url, selector) {
    if ($(selector).length) {
        return openModalDialog(selector)
    }

    $.get(url, function (result) {
        $('#dialogs').append(result)
    })
}

function closeModalDialog(selector) {
    $(selector).closest('.js-dialog-container').addClass('hidden')
}

function dialog(content) {
    const key = 'd' + Math.floor(Math.random() * 1000)

    const dialog = `
        <div id="${key}" class="dialog-container js-dialog-container">
            <div class="relative rounded bg-white py-[40px] px-[50px]">
                <div>${content}</div>
                
                <div class="pt-[30px] text-center">
                    <button class="sm-btn-blue js-close-dialog">OK</button>
                </div>
                <button class="absolute top-4 right-4 text-gray js-close-dialog"><i class="icon icon-close"></i></button>
            </div>
        </div>
    `

    $('#dialogs').append(dialog)

    openModalDialog('#' + key)
}

function show(el) {
    for (let i = 0; i < arguments.length; i++) {
        if ($(arguments[i]).length) {
            $(arguments[i]).show()
        }
    }
    return false
}

function fadeIn(el) {
    for (let i = 0; i < arguments.length; i++) {
        if ($(arguments[i]).length) {
            $(arguments[i]).fadeIn()
        }
    }
    return false
}

function fadeOut(el) {
    for (let i = 0; i < arguments.length; i++) {
        if ($(arguments[i]).length) {
            $(arguments[i]).fadeOut()
        }
    }
    return false
}

function hide(el) {
    for (let i = 0; i < arguments.length; i++) {
        if ($(arguments[i]).length) {
            $(arguments[i]).hide()
        }
    }
    return false
}

function submit(obj) {
    const form = $(obj).closest('form')
    if (!$(form).length) {
        return false
    }
    return $(form).submit()
}

function disable(obj) {
    if (!$(obj).length) {
        return false
    }
    return $(obj).attr('disabled', 'disabled')
}

function enable(obj) {
    if (!$(obj).length) {
        return false
    }
    return $(obj).removeAttr('disabled')
}

function toggle(els) {
    for (let i = 0; i < arguments.length; i++) {
        if ($(arguments[i]).length) {
            $(arguments[i]).toggle()
        }
    }
    return false
}

function slideTo(el) {
    const $el = $(el)
    if ($el.length) {
        $('html, body').animate({
            scrollTop: $el.offset().top
        }, 1000)
    }
}

function getQueryParam(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&')
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url)
    if (!results) return null
    if (!results[2]) return ''
    return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

function getDevicePixelRatio() {
    if ('devicePixelRatio' in window && window.devicePixelRatio) {
        return window.devicePixelRatio
    }
    return 1
}