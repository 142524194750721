function setSelectedAddress(fillType) {
    if (address === undefined) {
        return
    }
    const shouldClear = parseInt(fillType) === 0
    for (const i in address) {
        const el = $('#address-' + i.toLowerCase())
        if (el.length) {
            el.val(shouldClear ? '' : address[i])
        }
    }
    const state = $('#address-stateid')
    if (state.length) {
        state.trigger('change')
    }
}


$(document).ready(function () {
    $('.js-checkout form').submit(function () {
        if (!$(this).valid()) {
            return false
        }

        const submit = $(this).find('button:submit')
        if (submit.length) {
            submit.attr('disabled', 'disabled')
        }
    })

    $('.js-auth-type').on('click', function () {
        const self = $(this)

        $('.js-auth-type').parent().next('div').hide()
        self.parent().next('div').show()

        $('.js-auth-type-label').html(self.data('label'))
    })


    $('.js-default-address').on('change', function () {
        setSelectedAddress($(this).val())
    })

    $('.js-shipping-type').on('click', function () {
        $('.js-shipping-type').removeClass('font-bold')

        const self = $(this)
        const id = self.find('input').val()

        self.addClass('font-bold')

        $('.estimate').addClass('hidden')
        $('#estimate_' + id).removeClass('hidden')
    })

    $('input[name=asShipping]').change(function () {
        const fillType = $(this).is(':checked') ? 1 : 0

        setSelectedAddress(fillType)
    })

    $('.js-payment-type').change(function () {
        const self = $(this)
        const id = self.attr('id')
        const btnLabel = parseInt(self.val()) === 2 ? 'Continue to PayPal' : 'Pay now'

        $('.js-payment-boxes').hide()
        $(`#${id}Box`).show()

        $('#payButton').html(btnLabel)
    })

})
