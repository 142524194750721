;(function ($) {

    const reviewsForm = $('.js-review-form')
    const loader = $('.js-form-loader')
    const productReviewsPlaceholder = $('.js-review-placeholder')

    const toggleReviewForm = () => {
        reviewsForm.toggle()
        productReviewsPlaceholder.toggle()
    }

    $('.js-toggle-review-form').on('click', () => toggleReviewForm())

    let avatar = null
    const avatarContainer = $('.js-review-avatar')
    const avatarFileInput = $('.js-review-form [name=avatar]')
    const usernameInput = $('.js-review-form [name=username]')

    const getUsernameAvatar = () => {
        const username = usernameInput.val().trim()
        if (!username.length) {
            return null
        }
        const letter = username[0].toLowerCase()
        if (!letter.match(/[a-z]/i)) {
            return null
        }
        return `<img src="/img/avatars/${letter}.png" srcset="/img/avatars/${letter}__2x.png 2x">`
    }

    const removeUploadedAvatar = () => {
        avatar = null
        updateUserAvatar()
    }

    const updateUserAvatar = () => {
        avatarContainer.html('')
        if (!avatar) {
            const avatar = getUsernameAvatar()

            return avatar && avatarContainer.html(avatar)
        }
        const image = document.createElement('img')
        image.src = URL.createObjectURL(avatar)
        image.onload = () => URL.revokeObjectURL(image.src)
        image.classList.add('rounded-full', 'h-[40px]', 'w-[40px]');

        const btn = $('<button type="button" class="absolute rounded-full right-[-17px] top-[8px] text-center h-[25px] w-[25px] bg-gray-300"><i class="icon icon-close text-10 text-red"></i></button>')
        avatarContainer.html(image).append(
            btn.on('click', removeUploadedAvatar)
        )
    }

    usernameInput.on('input', function ({target}) {
        if (avatar || target.value.length > 1) {
            return
        }
        updateUserAvatar()
    })

    avatarFileInput.on('change', function ({target}) {
        if (target.files.length) {
            avatar = target.files[0]
        }
        updateUserAvatar()
    })


    const photos = {}
    const MAX_UPLOADED_FILES = 5
    const photosContainer = $('.js-review-photos')
    const photosFileInput = $('.js-review-photos-input')

    const removeSelectedPhoto = (id) => {
        const photo = photos[id]
        if (photo) {
            photo.template.remove()
            delete photos[id]
        }
    }

    const createPhotoItem = (id, file) => {
        const item = $('<div class="relative float-left mt-[40px] mr-[30px] last:mr-0">')
        const btn = $('<button type="button" class="absolute rounded-full right-[-12px] top-[-12px] text-center h-[25px] w-[25px] bg-gray-300"><i class="icon icon-close text-10 text-red"></i></button>')

        const image = document.createElement('img')
        image.src = URL.createObjectURL(file)
        image.onload = () => URL.revokeObjectURL(image.src)
        image.classList.add('rounded', 'h-[80px]', 'w-[120px]', 'object-contain');

        item.html(image).append(
            btn.on('click', () => removeSelectedPhoto(id))
        )
        photosContainer.append(item)
        return item
    }

    photosFileInput.on('change', function ({target}) {
        const files = Array.from(target.files)
        if (!files.length) {
            return
        }
        let quantityOfAvailableFiles = Math.min(MAX_UPLOADED_FILES - Object.keys(photos).length, target.files.length)
        while (quantityOfAvailableFiles) {
            const id = Math.random().toString(16).slice(2)
            const file = files.shift()
            photos[id] = {
                file,
                template: createPhotoItem(id, file)
            }
            --quantityOfAvailableFiles
        }
    })


    const orderSelect = $('.js-review-order')
    const primaryProduct = $('.js-review-product')
    const loadOrderPrimaryProduct = (orderId) => {
        $.get(`/order/${orderId}/primary-product/`, ({product}) => {
            if (!product) {
                return primaryProduct.hide().find('input').val('')
            }
            primaryProduct.find('span').html(product.caption)
            primaryProduct.find('input').val(product.id)
            primaryProduct.show()
        })
    }

    orderSelect.on('change', function () {
        this.value && loadOrderPrimaryProduct(this.value)
    })

    const emailInput = $('.js-review-email')

    emailInput.on('change', function () {
        const email = this.value.trim()
        if (!email) {
            return
        }

        $.getJSON('/order/search/', {email}, ({orders}) => {
            const options = orders.length ? orders : [{id: '', uid: 'unknown'}]
            orderSelect.html('')
            for (const {id, uid} of options) {
                orderSelect.append(`<option value="${id}">${uid}</option>`)
            }
            orderSelect.trigger('change')
        })
    })

    const submitBtn = $('.js-review-submit')

    const resetReviewForm = () => {
        reviewsForm[0].reset()
        removeUploadedAvatar()
        for (const id of Object.keys(photos)) {
            removeSelectedPhoto(id)
        }
    }

    submitBtn.on('click', function () {
        if (!reviewsForm.valid()) {
            return
        }
        const data = new FormData()
        for (const name of ['_csrf', 'email', 'username', 'stars', 'user', 'order', 'product', 'content']) {
            const value = reviewsForm.find(`[name=${name}]`).val()
            data.append(name, value)
        }

        avatar && data.append('avatar', avatar)

        for (const id of Object.keys(photos)) {
            data.append('photos[]', photos[id].file)
        }

        loader.removeClass('hidden')
        submitBtn.prop('disabled', true)
        $.ajax({
            data,
            type: 'POST',
            processData: false,
            contentType: false,
            url: '/review/store/',
            success: () => {
                productReviewsPlaceholder.html('<span>Thank you for your review!</span>')
                toggleReviewForm()
                resetReviewForm()
            },
            complete: () => {
                loader.addClass('hidden')
                submitBtn.prop('disabled', false)
            }
        })
    })

    $(document).ready(function () {
        const orderId = orderSelect.val()

        orderId && loadOrderPrimaryProduct(orderId)

        if (avatarContainer.length) {
            avatarContainer.html(getUsernameAvatar())
        }
    })
})(jQuery)
