;(function ($) {
    const STORE_FILES_URL = '/uploader/store-files/'
    const REMOVE_FILE_URL = '/uploader/remove-file/'
    const MAX_UPLOAD_SIZE = 100 * 1024 * 1024

    const items = {}
    const root = '.js-bulk-uploader '
    const fileSelect = $(root + 'input:file')
    const dropBox = $(root + '.js-drop-files')
    const dropBoxIcon = dropBox.find('.js-drop-file-icon')
    const selectedItems = $(root + '.js-selected-files')
    const uploadQueue = $(root + '.js-upload-queue')
    const addAllItemsBtn = $(root + '.js-all-items')
    const startUploadBtn = $(root + '.js-upload-submit')
    const uploadStatus = $(root + ' .js-upload-status')
    const readyItems = $(root + '.js-ready-items')
    const allowedExtensions = ['jpg', 'png', 'jpeg', 'tiff', 'tif', 'eps', 'pdf', 'psd', 'ai']
    const itemTemplate = '<tr class="odd:bg-gray-300"><td class="px-[15px] py-[4px] max-w-[340px] overflow-hidden">%file%</td><td class="px-[15px] py-[4px] max-w-[340px] overflow-hidden" colspan="2"><div class="progress-bar hide"><div style="width: 0"></div></div><span>&nbsp;</span><a href="javascript:void(0)" class="float-right js-remove-file" data-name="%file%"><img src="/img/uploader/remove-item.png" srcset="/img/uploader/remove-item__2x.png 2x"></a></td></tr>'

    function stopDefaultEvents(e) {
        e.preventDefault()
        e.stopPropagation()
    }

    function toggle() {
        (function toggleSelectedItems() {
            const qnt = uploadQueue.find('tr').length
            if (qnt) {
                return selectedItems.show()
            }
            return selectedItems.hide()
        })();
        (function toggleReadyItems() {
            const qnt = readyItems.find('tr').length
            if (qnt) {
                return readyItems.show()
            }
            return readyItems.hide()
        })();
        (function toggleUploadBtn() {
            startUploadBtn.hide()

            let uploading = false
            $.each(items, function () {
                uploading = uploading || this.uploading
            })

            if (!uploading) {
                startUploadBtn.show()
            }

        })();
        (function toggleDropBox() {
            dropBoxIcon.removeClass('bg-select-file-busy')
            fileSelect.prop('disabled', false)
            $.each(items, function () {
                if (this.uploading) {
                    dropBoxIcon.addClass('bg-select-file-busy')
                    fileSelect.prop('disabled', 'disabled')
                }
            })
        })();
        (function toggleUploadStatus() {
            uploadStatus.hide()
            $.each(items, function () {
                if (this.uploading) {
                    return uploadStatus.show()
                }
            })
        })();
        (function toggleSubmitBtn() {
            addAllItemsBtn.parent().hide()
            if (readyItems.find('tr').length) {
                addAllItemsBtn.parent().show()
            }
        })()
    }

    function onAmountChanged(e) {
        const el = e.target
        if (!el.min) return
        if (parseInt(el.value) < parseInt(el.min)) {
            el.value = el.min
        }
    }

    function addAllCartItems() {
        const self = $(this)
        setTimeout(function () {
            self.attr('disabled', 'disabled')
        }, 100)
    }

    function addSelectedFile(e) {
        const files = e.target.files.length ? e.target.files : e.originalEvent.dataTransfer.files
        $.each(files, function () {
            if (items[this.name] !== undefined) {
                return
            }
            const ext = this.name.split('.').pop().toLowerCase()
            if (allowedExtensions.indexOf(ext) === -1) {
                return error(this.name + ': Unsupported file format. Allowed formats - ' + allowedExtensions.join(', '))
            }
            if (this.size > MAX_UPLOAD_SIZE) {
                return error(this.name + ': File too large. Maximum file size is 100mb. Please submit a smaller file or a cloud link instead')
            }
            const item = {file: this, template: $(itemTemplate.replace(/%file%/g, this.name)), uploading: false}
            items[this.name] = item

            uploadQueue.append(item.template)
        })
        fileSelect.val('')
        toggle()
    }

    function removeSelectedFile() {
        const inputs = $(this).closest('tr').find('input:hidden')
        inputs.each(function () {
            $.post(REMOVE_FILE_URL, {id: $(this).val()})
        })
        const name = $(this).data('name')
        if (name) {
            delete items[name]
        }
        $(this).closest('tr').remove()
        toggle()
    }

    function startUpload() {
        const self = $(this)
        const queue = []
        const max = 5
        let i = 0

        uploadStatus.show()
        startUploadBtn.hide()
        dropBoxIcon.addClass('bg-select-file-busy')
        fileSelect.prop('disabled', 'disabled')

        function process(item) {
            const progress = item.template.find('.progress-bar')
            const removeBtn = item.template.find('.js-remove-file')
            const statusBoard = progress.next('span')

            if (item.uploading) {
                return
            }

            function loadStart() {
                progress.show()
                removeBtn.hide()
                item.uploading = true
            }

            function loadEnd() {
                progress.hide()
                removeBtn.show()
                item.uploading = false

                toggle()
            }

            function loadProgress(e) {
                const percent = (100 / e.total) * e.loaded
                const progressBar = progress.find('div')

                progressBar.animate({'width': percent + '%'}, 100)
                if (percent > 99) {
                    statusBoard.html('Generating preview')
                }
            }

            function loadComplete() {
                if (xhr.readyState !== 4) {
                    return
                }
                if (xhr.status !== 200) {
                    return statusBoard.html('Failed to store design file')
                }
                if (queue.length > 0) {
                    process(queue.shift())
                }
                const response = JSON.parse(xhr.responseText)
                delete items[item.file.name]

                let preview = response.preview
                if (preview) {
                    const amount = getQueryParam('amount')
                    if (amount) {
                        preview = preview.replace('[amount]" value="1"', `[amount]" value="${amount}"`)
                    }
                    readyItems.append(preview)
                }
                item.template.remove()
            }

            const xhr = new XMLHttpRequest()
            xhr.addEventListener('loadstart', loadStart)
            xhr.addEventListener('readystatechange', loadComplete)
            xhr.addEventListener('loadend', loadEnd)
            xhr.upload.addEventListener('progress', loadProgress)


            const data = new FormData()
            data.append('front', item.file)
            data.append('product', self.data('product'))
            if (window.innerWidth > 900) {
                data.append('showProofButton', '1')
            }

            xhr.open('POST', STORE_FILES_URL)
            xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest')
            xhr.send(data)
        }

        $.each(items, function () {
            i < max ? process(this) : queue.push(this)
            ++i
        })
    }

    function loadFileProof() {
        disable(this)
        const id = $(this).data('id')
        const pixelRatio = getDevicePixelRatio()
        $.post('/uploader/proof/', {id, pixelRatio}, (res) => {
            enable(this)

            res.content && dialog(res.content)

            if (res.error) {
                error(res.error)
                hide(this)
            }
        })
    }

    startUploadBtn.on('click', startUpload)
    fileSelect.on('change', addSelectedFile)
    addAllItemsBtn.on('click', addAllCartItems)
    uploadQueue.on('change', '.js-qnt', onAmountChanged)

    $(root)
        .on('click', '.js-remove-file', removeSelectedFile)
        .on('click', '.js-file-proof', loadFileProof)

    dropBox.on('drag dragstart dragend dragover dragenter dragleave drop', stopDefaultEvents)
    dropBox.on('drop', addSelectedFile)
})(jQuery)