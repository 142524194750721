;(function ($) {
    const STORE_FILES_URL = '/uploader/store-files/'
    const REMOVE_FILE_URL = '/uploader/remove-file/'
    const MAX_UPLOAD_SIZE = 100 * 1024 * 1024

    let selectedFiles = {}
    const root = '.js-single-uploader '
    const front = $(root + '.js-front-file')
    const back = $(root + '.js-back-file')
    const frontFile = front.find('input:file')
    const backFile = back.find('input:file')
    const frontName = front.find('.js-drop-file-name')
    const backName = back.find('.js-drop-file-name')
    const frontIcon = front.find('.js-drop-file-icon')
    const backIcon = back.find('.js-drop-file-icon')
    const dropBox = $(root + '.js-drop-files')
    const addAllItemsBtn = $(root + '.js-all-items')
    const progressBar = $(root + '.js-upload-progress')
    const uploadHelp = $(root + '.js-file-upload-help')
    const uploadStatus = $(root + '.js-upload-status')
    const readyItems = $(root + '.js-uploaded-items')
    const startUploadBtn = $(root + '.js-upload-submit')
    const allowedExtensions = ['jpg', 'png', 'jpeg', 'tiff', 'tif', 'eps', 'pdf', 'psd', 'ai']

    function toggle() {
        (function toggleReadyItems() {
            readyItems.hide()
            addAllItemsBtn.parent().hide()
            if (readyItems.find('tr').length) {
                readyItems.show()
                addAllItemsBtn.parent().show()
            }
        })();
        (function toggleFrontIcon() {
            frontIcon.removeClass('bg-ready-file')
            frontName.html('')
            if (selectedFiles.front) {
                frontIcon.addClass('bg-ready-file')
                console.log(frontIcon)
                frontName.html(selectedFiles.front.name)
            }
        })();
        (function toggleBackIcon() {
            backIcon.removeClass('bg-ready-file')
            backName.html('')
            if (selectedFiles.back) {
                backIcon.addClass('bg-ready-file')
                backName.html(selectedFiles.back.name)
            }
        })();
        (function toggleStartUploadBtn() {
            const isBackFile = selectedFiles.back
            if (selectedFiles.front && (!back.length || isBackFile)) {
                startUploadBtn.show()
                return startUploadBtn.removeAttr('disabled')
            }
            startUploadBtn.hide()
            startUploadBtn.attr('disabled', 'disabled')
        })()
    }

    function stopDefaultEvents(e) {
        e.preventDefault()
        e.stopPropagation()
    }

    function handleUploadStart() {
        frontIcon.addClass('bg-select-file-busy')
        frontFile.attr('disabled', 'disabled')

        backIcon.addClass('bg-select-file-busy')
        backFile.attr('disabled', 'disabled')

        uploadHelp.hide()
        startUploadBtn.hide()

        uploadStatus.show()
    }

    function handleUploadFinish() {
        selectedFiles = {}

        frontIcon.removeClass('bg-select-file-busy')
        frontFile.val('').removeAttr('disabled')

        backIcon.removeClass('bg-select-file-busy')
        backFile.val('').removeAttr('disabled')

        uploadHelp.show()
        uploadStatus.hide()

        toggle()
    }

    function isValidExt(file) {
        const ext = file.name.split('.').pop().toLowerCase()

        return allowedExtensions.indexOf(ext) !== -1
    }

    function isValidSize(file) {
        return file.size && file.size < MAX_UPLOAD_SIZE
    }

    function addAllCartItems() {
        const self = $(this)
        setTimeout(function () {
            self.attr('disabled', 'disabled')
        }, 100)
    }

    function onAmountChanged(e) {
        const el = e.target
        if (!el.min) return
        if (parseInt(el.value) < parseInt(el.min)) {
            el.value = el.min
        }
    }

    function setSelectedFile(e) {
        const files = e.target.files && e.target.files.length ? e.target.files
            : (e.originalEvent.dataTransfer ? e.originalEvent.dataTransfer.files : [])
        const side = $(e.target).prop('id')
            || $(e.target).closest('.js-drop-files').prop('id')
        const file = files[0]
        selectedFiles[side] = null
        if (file) {
            if (!isValidExt(file)) {
                return error(file.name + ': Unsupported file format. Allowed formats - ' + allowedExtensions.join(', '))
            }
            if (!isValidSize(file)) {
                return error('File too large. Maximum file size is 100mb. Please submit a smaller file or a cloud link instead')
            }
            selectedFiles[side] = file
        }
        toggle()
    }

    function removeUploadedFiles() {
        const inputs = $(this).closest('tr').find('input:hidden')
        inputs.each(function () {
            $.post(REMOVE_FILE_URL, {id: $(this).val()})
        })
        $(this).closest('tr').remove()
        toggle()
    }

    function uploadUserFiles() {
        function loadProgress(e) {
            const percent = (100 / e.total) * e.loaded

            progressBar.animate({'width': percent + '%'}, 50)
        }

        function loadComplete() {
            if (xhr.readyState !== 4 || xhr.status !== 200) {
                return
            }
            const response = JSON.parse(xhr.responseText)
            let preview = response.preview
            if (preview) {
                const amount = getQueryParam('amount')
                if (amount) {
                    preview = preview.replace('[amount]" value="1"', `[amount]" value="${amount}"`)
                }
                readyItems.append(preview)
            }
            if (response.error) {
                error(response.error)
            }

            toggle()
        }

        handleUploadStart()

        const xhr = new XMLHttpRequest()
        xhr.addEventListener('loadend', handleUploadFinish)
        xhr.addEventListener('readystatechange', loadComplete)
        xhr.upload.addEventListener('progress', loadProgress)

        const data = new FormData()
        data.append('front', selectedFiles.front)
        if (selectedFiles.back) {
            data.append('back', selectedFiles.back)
        }
        data.append('product', $(this).data('product'))
        if (window.innerWidth > 900) {
            data.append('showProofButton', '1')
        }

        xhr.open('POST', STORE_FILES_URL)
        xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest')
        xhr.send(data)
    }

    function loadFileProof() {
        disable(this)
        const id = $(this).data('id')
        const pixelRatio = getDevicePixelRatio()
        $.post('/uploader/proof/', {id, pixelRatio}, (res) => {
            enable(this)

            res.content && dialog(res.content)

            if (res.error) {
                error(res.error)
                hide(this)
            }
        })
    }

    $(root)
        .on('change', 'input:file', setSelectedFile)
        .on('click', '.js-remove-file', removeUploadedFiles)
        .on('change', '.js-qnt', onAmountChanged)
        .on('click', '.js-file-proof', loadFileProof)

    addAllItemsBtn.on('click', addAllCartItems)
    startUploadBtn.on('click', uploadUserFiles)

    dropBox.on('drag dragstart dragend dragover dragenter dragleave drop', stopDefaultEvents)
    dropBox.on('drop', setSelectedFile)
})(jQuery)