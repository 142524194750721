;(function ($) {

    function collectEmptyItemIds() {
        const items = []
        $('#cart .js-qnt').each(function () {
            const id = this.dataset.id
            const value = parseInt(this.value) || 0
            if (value === 0) {
                items.push(id)
            }
        })

        return items
    }

    $(document).ready(function () {

        $('#cart')
            .on('submit', 'form', function (e) {
                e.preventDefault()

                $.get('/cart/update/', $(this).serialize(), (cart) => $('#cart').html(cart))
            })
            .on('change', '.js-qnt', function () {
                const min = $(this).prop('min')
                if (min !== undefined) {
                    if (parseInt($(this).val()) < parseInt(min)) {
                        $(this).val(min)
                    }
                }
                return submit(this)
            })
            .on('click', '.js-remove', function (e) {
                e.preventDefault()

                if (!confirm('Are you sure?')) {
                    return false
                }
                $.get('/cart/remove/', {id: $(this).data('id')}, (data) => $('#cart').html(data))
            })
            .on('click', '.js-check-cart', function (e) {
                e.preventDefault()

                if (collectEmptyItemIds().length > 0) {
                    return openModalDialog('#emptyCartItems')
                }
                return window.location.href = $(this).attr('href')
            })

        $('#removeEmptyCartItems').on('click', function () {
            const ids = collectEmptyItemIds()

            if (ids.length > 0) {
                $.get('/cart/remove-bunch/', {id: ids.join(',')}, (data) => $('#cart').html(data))
            }

            closeModalDialog('#emptyCartItems')
        })

    })

})(jQuery)

