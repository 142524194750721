;(function ($) {

    function showDefaultCode() {
        const notice = $('.js-code-notice')
        const defaultNotice = notice.data('default')

        defaultNotice && notice.html(defaultNotice)

        const form = $('.js-discount-input')
        const status = $('.js-discount-status')
        status.fadeOut(200, function () {
            status.html('')
            form.fadeIn(200)
        })
    }

    function showDiscountCodeError(message) {
        const form = $('.js-discount-input')
        const status = $('.js-discount-status')

        form.fadeOut(200, function () {
            status.html(message).fadeIn(200)
            setTimeout(showDefaultCode, 2000)
        })
    }

    $(document).ready(function () {
        const refreshCartItems = function () {
            $.get('/cart/update/', $('#cart form').serialize(), (cart) => $('#cart').html(cart))
        }

        $('.js-discount-countdown').countdown()

        $(document).on('keyup', '.js-discount-input input[type=text]', showDefaultCode)

        $(document).on('submit', '.js-discount-form', function (e) {

            e.preventDefault()

            $.post('/cart/code/', $(this).serialize(), function (data) {
                if (data.error) {
                    setTimeout(refreshCartItems, 2000)

                    return showDiscountCodeError(data.error)
                }

                if (data.success) {
                    refreshCartItems()
                }

                showDefaultCode()
            }, 'json')
        })
    })

})(jQuery)




