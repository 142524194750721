;(function ($) {
    $.fn.countdown = function () {
        const self = $(this)
        const span = self.find('span')
        const dateEnd = self.data('end')

        if (!dateEnd || !span.length) {
            return this
        }

        function getTimeRemaining() {
            const serverTime = 60000 + self.data('time')

            const t = dateEnd - serverTime
            self.data('time', serverTime)

            return {
                'total': t,
                'times': {
                    'day': Math.floor(t / (1000 * 60 * 60 * 24)),
                    'hr': Math.floor((t / (1000 * 60 * 60)) % 24),
                    'min': Math.floor((t / 1000 / 60) % 60)
                }
            }
        }

        const interval = setInterval(function () {
            const period = getTimeRemaining(), content = []
            for (const i in period.times) {
                if (period.times[i] > 0) {
                    content.push(period.times[i] + ' ' + i)
                }
            }
            if (content.length) {
                span.html(content.join(' : '))
            }
            if (period.total <= 0) {
                clearInterval(interval)
                span.parent().remove()
            }
        }, 60000)
        return this
    }
}(jQuery))