;(function ($) {
    const STORE_LINK_URL = '/uploader/store-link-design/'
    const ADD_TO_CART_URL = '/cart/add/'

    $(document).ready(function () {
        const linkUpload = $('.js-link-uploader')
        const linkUploadBtn = linkUpload.find('button')
        const linkUploadInput = linkUpload.find('input[type=text]')

        function toggleLinkSubmit() {
            const link = linkUploadInput.val()

            if (link.length > 1) {
                return linkUploadBtn.removeAttr('disabled')
            }
            linkUploadBtn.attr('disabled', 'disabled')
        }

        linkUploadInput.on('keyup', toggleLinkSubmit)

        linkUploadBtn.on('click', function () {
            const options = $('#designParams').find('input').serialize()
            const params = options + '&' + $.param({link: linkUploadInput.val()})

            function handleSuccessRequest(data) {
                if (data.error) {
                    error(data.error)
                }
                if (data.design) {
                    window.location.href = ADD_TO_CART_URL + '?design=' + data.design.id
                }
            }

            $.post(STORE_LINK_URL, params, handleSuccessRequest)
        })

        $('.js-tab').on('click', function (e) {
            e.preventDefault()
            const self = $(this)
            const selfTab = self.data('tab')

            $('.js-tab').each(function () {
                const tab = $(this)
                const content = tab.data('tab')

                tab.addClass('bg-gray-300 text-gray-800').removeClass('bg-gray-800 text-white')

                selfTab === content ? $(content).show() : $(content).hide()
            })

            self.removeClass('bg-gray-300 text-gray-800').addClass('bg-gray-800 text-white')
        })
    })
})(jQuery)