$.ajaxSetup({cache: false})

$(document).ajaxError(function (event, xhr, settings, thrownError) {
    if (xhr.status !== 422) {
        return error(thrownError)
    }
    const errors = JSON.parse(xhr.responseText)

    $.each(errors, (i, o) => error(o.message))
})

$(document).ready(function () {
    $(document).on('submit', 'form', function () {
        return $(this).valid()
    })

    $('.digits').keypress(function (e) {
        if (e.which !== 8 && e.which !== 0 && (e.which < 48 || e.which > 57)) {
            return false
        }
    })

    $('.js-phone').mask('(999) 999-9999')

    $('.js-notification').each(function (i, o) {
        message($(o).html(), $(o).data('type'))
    })

    $('.js-one-click').on('click', function (e) {
        if (!$(this).hasClass('is-clicked')) {
            return $(this).addClass('is-clicked')
        }

        alert('Please reload page and try again')
        e.preventDefault()
    })

    $(document).on('click', '.js-close-dialog', function (e) {
        e.preventDefault()

        const dialog = $(this).closest('.js-dialog-container')
        if (dialog.length) {
            dialog.addClass('hidden')
        }
    })

    $(document).on('click', '.js-open-dialog', function (e) {
        e.preventDefault()

        const target = $(this).data('target')
        if (target) {
            openModalDialog(target)
        }
    })

    $(document).on('click', '.js-remote-dialog', function (e) {
        e.preventDefault()

        const url = $(this).data('url')
        const dialog = $(this).data('target')

        if (url && dialog) {
            loadModalDialog(url, dialog)
        }
    })

    const setTransformStyle = (container, angle) => {
        container.style.webkitTransform = `rotateY(${angle}deg)`
        container.style.MozTransform = `rotateY(${angle}deg)`
        container.style.msTransform = `rotateY(${angle}deg)`
        container.style.OTransform = `rotateY(${angle}deg)`
        container.style.transform = `rotateY(${angle}deg)`
    }

    $(document).on('click', '.js-flip-icon', function () {
        const container = $(this).parent().children(':first')[0]
        if (!container.style.transform) {
            return setTransformStyle(container, 180)
        }

        const angle = parseInt(
            container.style.transform.replaceAll(/\D/g, '')
        )
        return setTransformStyle(container, angle + 180)
    })
})