const invalidHandler = function (form, validator) {
    let firstElement
    const errors = validator.errorList
    for (const i in errors) {
        const element = $(errors[i].element)
        if (firstElement === undefined) {
            firstElement = element
        }
        let align = 'top'
        if (element.is(':radio') || element.is(':checkbox')) {
            const label = element.parent()
            if (label[0].tagName === 'LABEL') {
                label.addClass('text-red')
                align = 'right'
            }
        }
        firstElement.caption(errors[i].message, {align, color: 'red'})
    }

    if (firstElement) {
        firstElement.focus()
    }
}

const showValidationErrors = function () {
    for (const j in this.successList) {
        const element = $(this.successList[j])

        element.removeClass('text-red').caption(':remove')
        element.parent().removeClass('text-red').caption(':remove')
    }
}

$.validator.setDefaults({
    focusInvalid: false,
    invalidHandler: invalidHandler,
    showErrors: showValidationErrors
})

$.validator.addMethod('excludePOAPO', function (value, element) {
    const poReg = /(\s|^)(PO BOX|POBOX|PO|P.O|P.O.|P.O.BOX|PO-BOX|BOX)(\s|$)/i
    const apoReg = /(\s|^)(APO BOX|APOBOX|APO|A.P.O|A.P.O.|A.P.O.BOX|APO-BOX)(\s|$)/
    return this.optional(element) || (!poReg.test(value) && !apoReg.test(value))
}, 'We don\'t ship to PO or APO addresses')

$.validator.addClassRules('exclude-po', {excludePOAPO: true})