;(function ($) {

    $(document).ready(function () {

        $(document).on('submit', '.js-change-options', function (e) {
            e.preventDefault()

            const form = $(this)

            form.find('.js-form-loader').removeClass('hidden')


            $.post(form.prop('action'), form.serialize(), function () {
                form.find('.js-form-loader').addClass('hidden')

                $.get('/cart/update/', $('#cart form').serialize(), function (cart) {
                    $('#cart').html(cart)
                })

                form.closest('.js-dialog-container').remove()
            })
        })

        $(document).on('change', '.js-change-options select', function () {
            const options = [], selects = $(this).closest('form').find('select')
            selects.each(function (i, obj) {
                options.push($(obj).val())
            })
            selects.each(function (i, obj) {
                let defaultOption = null, shouldSetDefault = false
                $.each(obj.options, function (j, option) {
                    const id = option.value
                    const parent = option.dataset.parent
                    if (parent === '') {
                        defaultOption = defaultOption || option
                        return
                    }
                    if (options.indexOf(parent) !== -1) {
                        defaultOption = defaultOption || option
                        return option.style.display = 'inline'
                    }
                    option.style.display = 'none'
                    if (options.indexOf(id) !== -1) {
                        shouldSetDefault = true
                    }
                })
                if (shouldSetDefault && defaultOption) {
                    $(obj).val(defaultOption.value).trigger('change')
                }

            })
        })

    })

})(jQuery)
