;(function ($) {

    $(document).on('change', '.js-product-options select, .js-product-options input', function () {
        const self = $(this)

        if (self.data('no-submit')) {
            return
        }

        self.closest('form').submit()
    })

    $(document).on('submit', '.js-product-options', function (e) {
        e.preventDefault()

        const form = $(this)

        $.get(form.prop('action'), form.serialize(), function (result) {
            $('.js-product-constructor').html(result)
        })
    })

    $(document).on('keydown', '.js-product-options input[name=amount]', function (event) {
        if (event.keyCode === 13) {
            event.preventDefault()
            const form = $(this).closest('form')
            $.get(form.prop('action'), form.serialize(), function (result) {
                $('.js-product-constructor').html(result)
            })
            return false
        }
    })

    const submitConstructorWithParams = (url) => {
        const container = $('.js-product-options')
        if (container.length) {
            const params = $('<form/>').html(container.html()).serialize()

            window.location.href = `/${url}/?${params}`
        }
    }

    $(document).on('click', '.js-constructor-uploader', function (e) {
        e.preventDefault()

        submitConstructorWithParams('uploader')
    })

    $(document).on('click', '.js-constructor-designer', function (e) {
        e.preventDefault()

        submitConstructorWithParams('designer')
    })

    $(document).on('click', '.js-rates-submit', function (e) {
        e.preventDefault()

        const parent = $(this).parent()
        const form = $('.js-product-options')

        const params = {
            zip: parent.find('input[name=zip]').val(),
            qnt: parent.find('input[name=qnt]').val(),
            size: form.find('input[name=size]').val(),
            product: form.find('input[name=product]').val()
        }

        const submitBtn = $(e.target)
        const resultContainer = $('.js-delivery-options')
        const flushToDefaultState = () => {
            resultContainer.html('')
            submitBtn.removeAttr('disabled')
        }

        submitBtn.attr('disabled', true)
        $.post('/shipping/delivery-options/', params, (result) => {
            flushToDefaultState()
            const table = $('<table class="w-full mt-[20px] text-12">')
            for (const {caption, price, eta} of result) {
                table.append(`<tr><td>${caption}</td><td>${eta}</td><td>${price}</td></tr>`)
            }
            resultContainer.append(table)
        }).fail(flushToDefaultState)
    })

    $(document).on('click', '.js-custom-select button', function (e) {
        const customSelect = $(this).closest('.js-custom-select')
        const customSelectOptions = customSelect.find('ul')
        if (customSelectOptions.hasClass('js-load-sizes')) {
            const params = {
                product: customSelectOptions.data('product'),
                selected: customSelectOptions.data('selected')
            }
            $.get('/product-sizes/', params, function (result) {
                customSelectOptions.html($(result).find('li'))
            })

            customSelectOptions.removeClass('js-load-sizes')
        }
    })

})(jQuery)
