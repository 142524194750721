;(function ($) {
    const slider = $('#lightSlider')
    const isGallery = slider.data('gallery')

    if (slider.length) {
        slider.lightSlider({
            item: 1,
            auto: false,
            pause: 5000,
            pauseOnHover: true,
            gallery: isGallery,
            pager: isGallery,
            controls: false,
            thumbItem: 6,
            thumbMargin: 5
        })
    }
})(jQuery)